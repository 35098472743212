import React from "react";
import Img from "gatsby-image";
import Heading from "../Heading";
import TransitionLink from "../TransitionLink";
import { Waypoint } from "react-waypoint";

export const Mobile = ({
  pageData,
  linkClicked,
  setLinkClicked,
  setPageColor,
  setActiveImage
}) => {
  const handleWaypointEnter = image => {
    if (linkClicked) return;
    setActiveImage(image);
  };

  const handleEndWaypointEnter = () => {
    setActiveImage(null);
    setPageColor("white");
  };

  const handleClick = () => {
    setLinkClicked(true);
    setActiveImage(null);
    setPageColor("white");
  };

  return (
    <div className="pt-6 md:pt-10">
      {pageData.primary_menu.map((item, index) => {
        if (!item.link.document || !item.image.fluid || !item.title)
          return null;
        let galleryCount;
        if (item.link.document.data) {
          galleryCount = item.link.document.data.images.length;
        }
        const ratio = (1 / item.image.fluid.aspectRatio) * 100;
        return (
          <div key={index} className="mb-58 relative">
            <div
              className="absolute pin-t inset-x-0 z-20 text-center"
              style={{ transform: "translateY(-62%)" }}
            >
              <Heading
                text={item.title}
                count={galleryCount}
                className="text-white justify-center"
              />
            </div>
            <Waypoint onEnter={() => handleWaypointEnter(item.image.fluid.src)}>
              <TransitionLink
                to={item.link.url}
                onClick={() => handleClick()}
                className="block sm:w-2/3 mx-auto relative z-10"
              >
                <div
                  style={{ paddingBottom: `${ratio}%` }}
                  className="relative w-full h-0 bg-smoke overflow-hidden"
                >
                  <Img
                    fluid={item.image.fluid}
                    alt={item.image.alt}
                    className="inset-0 w-full h-full object-cover"
                    style={{ position: "absolute" }} // Required to override Gatsby Image's default position relative
                  />
                </div>
              </TransitionLink>
            </Waypoint>
            <div className="dash"></div>
          </div>
        );
      })}
      <Waypoint onEnter={() => handleEndWaypointEnter()} />
    </div>
  );
};
