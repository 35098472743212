import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { usePalette } from "react-palette";
import { isMobile } from "react-device-detect";
import { Desktop } from "../components/Home/Desktop";
import { Mobile } from "../components/Home/Mobile";

const IndexPage = ({ data, location }) => {
  const [pageColor, setPageColor] = useState("white");
  const [activeImage, setActiveImage] = useState(null);
  const [linkClicked, setLinkClicked] = useState(false);
  const [hasWindow, setHasWindow] = useState(false);

  const palette = usePalette(activeImage);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, [hasWindow]);

  useEffect(() => {
    if (linkClicked) return;
    if (Object.keys(palette.data).length === 0) return;

    const shades = Object.keys(palette.data);
    const shade = Math.floor(Math.random() * shades.length);
    const color = palette.data[shades[shade]];

    setPageColor(color);
  }, [palette, linkClicked]);

  const pageData = data.allPrismicGlobal.nodes[0].data;

  if (!hasWindow) return null;

  return (
    <Layout
      pageColor={pageColor}
      seoTitle={pageData.site_meta_title || "Home"}
      seoDescription={pageData.site_meta_description}
      location={location}
    >
      {isMobile ? (
        <Mobile
          pageData={pageData}
          linkClicked={linkClicked}
          setLinkClicked={setLinkClicked}
          setActiveImage={setActiveImage}
          setPageColor={setPageColor}
        />
      ) : (
        <Desktop
          pageData={pageData}
          setPageColor={setPageColor}
          setActiveImage={setActiveImage}
        />
      )}
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default IndexPage;

export const query = graphql`
  query {
    allPrismicGlobal {
      nodes {
        data {
          site_meta_description
          site_meta_title
          primary_menu {
            title
            link {
              url
              document {
                ... on PrismicGallery {
                  data {
                    images {
                      title1
                    }
                  }
                }
              }
            }
            image {
              alt
              fluid(srcSetBreakpoints: [380, 640, 1280]) {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`;
