import React, { useState } from "react";
import Img from "gatsby-image";
import { CSSTransition } from "react-transition-group";
import Heading from "../Heading";
import TransitionLink from "../TransitionLink";

export const Desktop = ({ pageData, setPageColor, setActiveImage }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [linkClicked, setLinkClicked] = useState(false);

  const handleMouseLeave = () => {
    if (linkClicked) return;
    setActiveItem(null);
    setActiveImage(null);
    setPageColor("white");
  };

  const handleMouseEnter = (index, image) => {
    if (linkClicked) return;
    setActiveItem(index);
    setActiveImage(image);
  };

  const handleClick = () => {
    setLinkClicked(true);
    setActiveItem(null);
    setActiveImage(null);
    setPageColor("white");
  };

  const Overlay = (item, index) => {
    if (!item.image.fluid) return null;
    return (
      <CSSTransition
        key={index}
        in={activeItem === index}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="transition-fade"
      >
        <div className="absolute flex justify-center inset-0 transition-fade-default">
          <Img
            className="w-full"
            style={{ maxWidth: "40vw" }}
            fluid={item.image.fluid}
            alt={item.image.alt || item.title}
          />
        </div>
      </CSSTransition>
    );
  };

  const MenuItem = (item, index) => {
    if (!item.link.document || !item.image.fluid || !item.title) return null;
    let galleryCount;
    if (item.link.document.data) {
      galleryCount = item.link.document.data.images.length;
    }

    return (
      <div key={index} className={`flex ${index % 2 ? "self-end" : ""}`}>
        <TransitionLink
          to={item.link.url}
          role=""
          className={`block transition-colors duration-200 ${
            activeItem === index ? "z-30 text-white" : "z-10"
          }`}
          onMouseEnter={() => handleMouseEnter(index, item.image.fluid.src)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick()}
        >
          <Heading
            text={item.title}
            count={galleryCount}
            className="3xl:text-4xl"
          />
        </TransitionLink>
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="fixed w-1/2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none z-20">
        <div className="pb-1/1">
          {pageData.primary_menu.map((item, index) => Overlay(item, index))}
        </div>
      </div>
      <div className="flex flex-col space-y-8">
        {pageData.primary_menu.map((item, index) => MenuItem(item, index))}
      </div>
    </div>
  );
};
